
import './App.css';
import Navbar from './Components/Navbar';
import News from './Components/News';
import { BrowserRouter, Routes,Route} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'


//the below is called class based component in react
import React,{useState} from 'react';
//in class based component we called method using this keyword


const App = () => {
 const pageSize = 9;
  const apiKey = process.env.REACT_APP_NEWS_API
 //for accessing environmental varibale you want to add process.enc
  //render is life cycle method means when it run some series of method are runs
 
  const[progress,setProgress] = useState(0)
    return (
      <div>
        <BrowserRouter>
        
        <Navbar />  
        <LoadingBar
        height={3}
        color='#f11946'
          progress={progress}
        
      />
        
        <Routes>
            <Route exact path="/" element={<News setProgress={setProgress} apiKey={apiKey} key="general" pageSize={pageSize} country="in" category="general" />} />
            <Route exact path="/general" element={<News setProgress={setProgress} apiKey={apiKey} key="general" pageSize={pageSize} country="in" category="general" />} />
            <Route exact path="/business" element={<News setProgress={setProgress} apiKey={apiKey} key="business" pageSize={pageSize} country="in" category="business" />} />
            <Route exact path="/entertainment" element={<News setProgress={setProgress} apikey={apiKey} key="entertainment" pageSize={pageSize} country="in" category="entertainment" />} />
            <Route exact path="/health" element={<News setProgress={setProgress} key="health" apiKey={apiKey} pageSize={pageSize} country="in" category="health" />} />
            <Route exact path="/science" element={<News setProgress={setProgress} key="science" apiKey={apiKey} pageSize={pageSize} country="in" category="science" />} />
            <Route exact path="/sports" element={<News setProgress={setProgress} key="sports" apiKey={apiKey} pageSize={pageSize} country="in" category="sports" />} />
            <Route exact path="/technology" element={<News setProgress={setProgress} key="technology" apiKey={apiKey} pageSize={pageSize} country="in" category="technology" />} />
        </Routes>
        </BrowserRouter>
        
      </div>
    )
  
}
export default App;



