import React from 'react'


const Newsitem = (props) => {
    //props are read only that we cannot update for updating we use state 
    //we want identify the news by their unic id we can use  url bcs every url in unique
    let  {title,decription,imgUrl,newsUrl,author,date,source} = props           //this.props;
        return (
            <div className='my-4 mx-1'>
                <div className="card" >
                    <div style={{
                        display:'flex',
                        justifyContent:'flex-end',
                        position:'absolute',
                        right:'0'
                    }
                    }>
                 <span className=" badge rounded-pill bg-danger" >
                        {source}</span> 

                    </div>
                 <img src={imgUrl?imgUrl:"https://a4.espncdn.com/combiner/i?img=%2Fi%2Fcricket%2Fcricinfo%2F1219926_1296x729.jpg"} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <h5 className="card-title">{title}...</h5>
                        <p className="card-text">{decription}...</p>
                        <p className='card-text'><small className='text-muted'>By {author?author:"Unknown"} on {new Date(date).toGMTString()}</small></p>
                        <a href={newsUrl} target="_blank" className="btn btn-sm btn-dark">Read More</a>
                        {/*using target _blank the new will be open in the new tab after clicking read more
                         using bootstrap class btn-sm the red more btn will be small(sm) */}
                </div>
            </div>
        </div>
        )
    
}

export default Newsitem;